import * as React from "react"
import '../styles/global.scss';
import Layout from "../components/Layout/Layout";
export default class PrivacyPage extends React.Component {
    public render() {
        return (
            <Layout isHomePage={false}>
                <div className={'row mt-4'}>
                    <div className={'col-12'}>
                        <h2>Regulamin ogólny korzystania przez Licencjobiorcę z platformy „LOGGY”</h2>

                        <h3>I. Słowniczek pojęć (Definicje regulaminowe)</h3>
                        <p><strong>1. Regulamin ogólny</strong> – oznacza poniższy Regulamin, wraz z jego
                            ewentualnymi późniejszymi zmianami – który określa zasady udzielenia Licencjobiorcy
                            przez
                            Licencjodawcę licencji na korzystanie z określonego w jego treści Pakietu,
                            wybranego przez Licencjobiorcę w przypisanym mu okresie abonamentowym.</p>

                        <p><strong>2. Licencjodawca</strong> - oznacza LOGGY SPÓŁKĘ Z OGRANICZONA
                            ODPOWIEDZIALNOSCIA - z siedzibą w Warszawie, ul. Świeradowska 47, 02-662
                            Warszawa, wpisaną do rejestru przedsiębiorców, prowadzonego przez Sąd
                            Rejonowy dla m.st. Warszawy, Wydział XIII Gospodarczy Krajowego Rejestru
                            Sądowego pod nr KRS 0000802199, o kapitale zakładowym w wysokości
                            5.000,00 złotych NIP: 521-387-55-58, REGON 384286533. Jakakolwiek zmiana
                            formy prawnej prowadzenia działalności Licencjodawcy, zmiana jego nazwy,
                            firmy, siedziby, wysokości kapitału zakładowego, zmiany osobowej udziałowców,
                            nie stanowi zmiany Regulaminu.</p>

                        <p>
                            <strong>3. Licencjobiorca</strong> – oznacza każdy podmiot - osobę fizyczną, osobę
                            prawną lub
                            jednostkę organizacyjną posiadającą zdolność prawną, która zawarła Umowę z
                            Licencjodawcą. Licencjobiorca oświadcza, że nie jest konsumentem w rozumieniu
                            art. 22 1 Ustawy Kodeks cywilny (Dz.U.1964.16.94) ze zm. i godzi się na to, by
                            prawa i obowiązki, zarówno jej, jak i Licencjodawcy, określał niniejszy
                            Regulamin;</p>

                        <p>
                            <strong>4. Licencja</strong> – oznacza udzieloną dla danego Licencjobiorcy
                            licencję niewyłączną na
                            korzystanie z oznaczonego Pakietu (Basic, Business, Max);</p>

                        <p><strong>5. Strony</strong> – Licencjodawca i Licencjobiorca;</p>

                        <p><strong>6. Platforma Loggy </strong>- użytkowy program komputerowy będący własnością
                            Licencjodawcy (Licencjodawcy przysługują wszelkie prawa autorskie, w
                            rozumieniu Ustawy o prawie autorskim i o prawach pokrewnych Dz. U. 1994 Nr
                            24 poz. 83 ze zm.,) udostępniany on – Line Licencjobiorcy za pomocą
                            internetowego serwisu pod adresem <a
                                href="https://panel.loggy.pl">https://panel.loggy.pl</a></p>

                        <p><strong>7. Aplikacja Loggy</strong> - użytkowa aplikacja mobilna, będąca
                            elementem platformy
                            Loggy udostępniana za pomocą urządzeń mobilnych.</p>

                        <p><strong>8. Baza Danych </strong>- zbiór danych lub jakichkolwiek innych
                            materiałów i elementów
                            zgromadzonych według określonej systematyki lub metody, indywidualnie
                            dostępnych w jakikolwiek sposób, w tym środkami elektronicznymi, wymagający
                            istotnego, co do jakości lub ilości, nakładu inwestycyjnego w celu
                            sporządzenia,
                            weryfikacji lub prezentacji jego zawartości, który jest wykorzystywany w
                            ramach
                            funkcjonowania Aplikacji Loggy;</p>

                        <p>
                            <strong>9. Pakiet</strong> - oznacza zbiór funkcjonalności działających w
                            ramach Platformy
                            i Aplikacji Loggy, jak również zestawy funkcjonalności, bazy danych, oraz
                            inne
                            dane lub informacje uzyskiwane w ramach działalności Platformy i Aplikacji
                            Loggy.</p>

                        <p>
                            <strong>10. Regulamin opłat</strong> – regulamin zawierający opis rodzajów
                            pakietów Platformy
                            i Aplikacji Loggy, wraz z cennikiem i zasadami płatności, w zakresie
                            nieuregulowanym regulaminem ogólnym. Zmiana regulaminu opłat nie stanowi
                            zmiany regulaminu ogólnego i następuje poprzez umieszczenie jej na
                            stronie <a
                            href="https://loggy.pl/regulamin-oplat">https://loggy.pl/regulamin-oplat</a>
                        </p>

                        <p>
                            <strong>11. Regulamin aplikacji mobilnej Loggy</strong> – regulamin
                            określający zasady
                            korzystania z aplikacji mobilnej Loggy, w zakresie nieuregulowanym
                            regulaminem ogólnym. Zmiana regulaminu aplikacji mobilnej Loggy nie stanowi
                            zmiany regulaminu ogólnego i następuje poprzez umieszczenie jej na
                            stronie <a href="https://loggy.pl/regulamin-aplikacji-mobilnej">
                            https://loggy.pl/regulamin-aplikacji-mobilnej.</a></p>

                        <h3>II. Postanowienia ogólne</h3>
                        <p>1. Licencja zostaje zawarta na podstawie niniejszego regulaminu. Strony zgodnie
                            oświadczają, że określa on ich prawa i obowiązki.</p>

                        <p>2. Strony zgodnie postanawiają, że mogą zawrzeć odrębną umowę, której
                            postanowienia zostaną ustalone na podstawie indywidualnych negocjacji
                            pomiędzy nimi. Postanowienia takiej umowy zostaną objęte klauzulą poufności.</p>

                        <p>3. W przypadku zawarcia umowy której postanowienia będą sprzeczne lub
                            odmienne od postanowień niniejszego Regulaminu, zastosowanie będą miały
                            postanowienia umowne – w pozostałym zakresie zastosowanie będzie miał
                            Regulamin.</p>

                        <p>4. Licencjodawca oferuje Pakiety w następujących opcjach:
                            <ul>
                                <li>a) FREE</li>
                                <li>b) BASIC</li>
                                <li>c) BUSINESS</li>
                            </ul>
                            Szczegółowy zakres funkcjonalności poszczególnych Pakietów, wraz z cennikiem
                            znajduje się w Regulaminie opłat.
                        </p>
                        <p>5. Przed udzieleniem odpłatnej licencji,
                            Licencjodawca zobowiązuje się udostępnić
                            potencjalnemu Licencjobiorcy, do nieodpłatnego użytku, pakiet w wersji Basic
                            na okres testowy 30 dni.</p>

                        <p>6. Licencjodawca zobowiązuje się udostępnić
                            potencjalnemu Licencjobiorcy, do nieodpłatnego użytku, pakiet w wersji Free
                            na czas nieokreślony.</p>

                        <p>7. W przypadku upływu okresu testowego i nie wykupienia żadnej opcji Pakietu,
                            Licencjobiorca traci dostęp do wszystkich funkcjonalności systemu, a jego dane
                            zostają usunięte z bazy danych Loggy w ciągu 30 dni roboczych.</p>

                        <p>8. Licencjodawca zastrzega sobie możliwość zmian wysokości abonamentu za
                            konkretne pakiety. Zmiany będą obowiązywać od następnego okresu
                            abonamentowego, następującego po okresie w którym wprowadzono zmiany.
                            Zmiany wysokości abonamentu mogą być dokonane do 10 dnia każdego
                            miesiąca, poprzez wprowadzenie ich do regulaminu opłat umieszczonego na
                            stronie http://loggy.pl/#cennik.</p>

                        <p>9. W trakcie okresu abonamentowego Licencjobiorca może zmienić Pakiet na
                            wyższy. Zmienić Pakiet na niższy Licencjobiorca może jedynie po zakończeniu
                            okresu abonamentowego.</p>

                        <p>10. Za datę dokonania płatności uznaje się dzień zaksięgowania środków na
                            rachunku Licencjodawcy.</p>

                        <h3>III. Odpowiedzialność Licencjodawcy. Siła wyższa.</h3>
                        <p>1. Wyłącza się odpowiedzialność Licencjodawcy za wszelkie skutki związane
                            z korzystaniem z Platformy Loggy i Aplikacji Loggy, w szczególności za:
                            <ul>
                                <li>- szkody wynikłe z użytkowania lub niemożliwości ich użytkowania, jak
                                    również
                                </li>
                                <li>- za szkody wynikłe z wad udostępnianego przez Licencjodawcę
                                    Licencjobiorcy
                                    oprogramowania czy ew. błędów pojawiających się w trakcie funkcjonowania
                                    Platformy Loggy lub Aplikacji Loggy lub nieprawdziwych danych
                                    znajdujących
                                    się na Platformie Loggy lub w Aplikacji Loggy.
                                </li>
                            </ul>
                        </p>

                        <p>2. Powyższe wyłączenie odpowiedzialności obowiązuje także po rozwiązaniu lub
                            wygaśnięciu Umowy, niezależnie od tego, w jakim trybie doszło do jej
                            rozwiązania czy wygaśnięcia.</p>

                        <p>3. Wyłącza się odpowiedzialność Licencjodawcy z tytułu rękojmi za wady Platformy
                            lub Aplikacji Loggy, niezależnie od ich natury i charakteru.</p>

                        <p>4. Licencjodawca nie odpowiada za niewykonanie Umowy spowodowane siłą
                            wyższą. Przez siłę wyższą należy rozumieć zdarzenie zewnętrzne, o charakterze
                            nadzwyczajnym, któremu nie można zapobiec, nawet przy zachowaniu
                            najwyższego stopnia staranności, np. kataklizmy, nie dające się przewidzieć
                            awarie, powodzie, trzęsienia ziemi, wojna. itp.</p>

                        <p>5. Każda ze Stron powinna poinformować drugą stronę o zaistnieniu siły wyższej,
                            niezwłocznie po otrzymaniu wiadomości o tym fakcie, a jeżeli z powodu siły
                            wyższej nie byłoby to możliwe, niezwłocznie po jej ustaniu - oraz dołożyć
                            wszelkich starań w celu usunięcia skutków działań siły wyższej dla wykonywania
                            zobowiązań zaciągniętych w Umowie.</p>

                        <h3>IV. Wymogi techniczne</h3>

                        <p>1. Aktualne wymogi techniczne niezbędne do korzystania z Platformy Loggy to:
                            <ul>
                                <li>- procesor 1800 MHz</li>
                                <li>- pamięć RAM: 2 GB</li>
                                <li>- podłączenie do Internetu</li>
                                <li>- aktualna przeglądarka Chrome / Safari / Firefox</li>
                            </ul>
                        </p>

                        <p>2. Licencjobiorca oświadcza, że zapoznał się z nimi.</p>

                        <p>3. Wymogi techniczne mogą ulec zmianie. Zmiana taka nie stanowi zmiany
                            Regulaminu.</p>

                        <p>4. Koszty dostosowania się do wymogów technicznych niezbędnych do korzystania
                            z Platformy Loggy lub Aplikacji Loggy (także wynikające ze zmian
                            wprowadzonych w trakcie okresu abonamentowego), w tym wyposażenia w
                            stosowną przeglądarkę - ponosi Licencjobiorca.</p>

                        <p>5. Strony zgodnie oświadczają, że Licencjodawca ma prawo do zawieszenia
                            funkcjonowania Platformy Loggy i Aplikacji Loggy w celu ich serwisowania.</p>

                        <p>6. Licencjodawca dołoży wszelkich starań, aby przerwy trwały jak najkrócej
                            i odbywały się w godzinach nocnych.</p>

                        <p>7. W przypadku gdy zawieszenie funkcjonowania Platformy Loggy i Aplikacji Loggy
                            trwa dłużej niż 24h Licencjobiorca ma prawo rościć sobie prawo do zwrotu ceny
                            proporcjonalnie do wysokości abonamentu za dany pakiet i do okresu czasu
                            zawieszenia funkcjonowania. Zapis niniejszy nie dotyczy sytuacji, gdy
                            zawieszenie funkcjonowania Platformy Loggy i Aplikacji Loggy nie nastąpiło z
                            przyczyn leżących po stronie Licencjodawcy.</p>

                        <h3>V. Reklamacje</h3>

                        <p>1. Reklamacje dotyczące funkcjonowania Platformy Loggy i Aplikacji Loggy mogą
                            być składane przez Licencjobiorcę tylko w okresie abonamentowym
                            przypisanym temu Pakietowi, którego dotyczą.</p>

                        <p>2. Wszelkie reklamacje, błędy i nieprawidłowości w działaniu Platformy Loggy
                            i Aplikacji Loggy mogą być zgłaszane Licencjodawcy niezwłocznie, nie później
                            jednak niż w ciągu 3 dni od dnia zaistnienia błędu lub nieprawidłowości - na
                            adres <a href="mailto:pomoc@loggy.pl">pomoc@loggy.pl</a>.</p>

                        <p>3. Każda reklamacja winna zawierać:
                            <ul>
                                <li>a) dane Licencjobiorcy pozwalające na ustalenie, że ma on aktualny
                                    status
                                    Licencjobiorcy,
                                </li>
                                <li>b) dane dotyczące Pakietu,</li>
                                <li>c) szczegółowy opis problemu, pojawiającego się błędu lub
                                    nieprawidłowości,
                                </li>
                            </ul>
                        </p>

                        <p>4. Prawidłowo złożona reklamacja będzie rozpatrywana przez Licencjodawcę
                            niezwłocznie, nie dłużej jednak niż w terminie 14 (czternaście) dni od dnia jej
                            Zgłoszenia, chyba że jej rozpatrzenie w w/w terminie niemożliwe ze względu na
                            zawiłość problemu. W takiej sytuacji Licencjodawca poinformuje mailowo
                            Licencjobiorcę o tym fakcie i wskaże przewidywany termin rozpatrzenia
                            reklamacji.</p>

                        <p>5. Załatwienie reklamacji będzie polegać na zidentyfikowaniu przyczyn problemu,
                            pojawiającego się błędu lub nieprawidłowości - leżących po stronie
                            Licencjodawcy a następnie ich usunięciu.</p>

                        <p>6. Załatwienie reklamacji Może także polegać na ustaleniu innych przyczyn (nie
                            leżących po stronie Licencjodawcy) - jeżeli będzie możliwe (bez ponoszenia
                            kosztów
                            przez Licencjodawcę).</p>

                        <h3>VI. Zmiana Regulaminu ogólnego</h3>

                        <p>1. Licencjodawca ma prawo do zmiany Regulaminu ogólnego.</p>

                        <p>2. O zmianie Regulaminu ogólnego Licencjodawca informuje Licencjobiorcę na 7 dni
                            przed wejściem w życie tych zmian poprzez wysłanie wiadomości pocztą
                            elektorniczną
                            na adres mailowy Licencjobiorcy podany podczas rejestracji.</p>

                        <p>3. W przypadku, gdy Licencjobiorca nie wyraża zgody na zmianę Regulaminu, ma
                            prawo do wypowiedzenia umowy na piśmie pod rygorem nieważności, z
                            miesięcznym okresem wypowiedzenia.</p>

                        <p>4. Licencjodawca zapewnia dostęp do Regulaminu w taki sposób, że możliwe jest
                            jego pobranie, utrwalenie oraz wydrukowanie.</p>

                        <p>5. Strony zgodnie oświadczają, że zmiana Regulaminu opłat i Regulaminu aplikacji
                            mobilnej Loggy nie stanowi zmiany Regulaminu ogólnego.</p>

                        <h3>VII. Postanowienia końcowe, ochrona danych osobowych</h3>

                        <p>1. Licencjobiorca zawierając Umowę oświadcza tym samym, że akceptuje
                            Regulamin bez zastrzeżeń, a jeżeli jest osobą fizyczną, to dodatkowo także,
                            że nie ma statusu konsumenta.</p>

                        <p>2. Zmiany określone w treści Regulaminu opisane jako niestanowiące zmiany
                            Regulaminu nie uprawniają do rozwiązania Umowy w trybie tam opisanym.</p>

                        <p>3. Licencjobiorca winien informować pisemnie Licencjodawcę o każdej zmianie
                            adresu wykonywanej działalności gospodarczej jak i adresu do korespondencji.</p>

                        <p>4. Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych z dnia
                            27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016) Licencjodawca informuje,
                            iż:
                            <ul>
                                <li>a) administratorem danych osobowych Licencjobiorcy oraz podmiotów przez
                                    niego wskazanych w ramach korzystania z Platformy i Aplikacji Loggy jest

                                    LOGGY SPÓŁKA Z OGRANICZONA ODPOWIEDZIALNOSCIA - z siedzibą w
                                    Warszawie, ul. Świeradowska 47, 02-662 Warszawa, wpisaną do rejestru
                                    przedsiębiorców, prowadzonego przez Sąd Rejonowy dla m.st. Warszawy,
                                    Wydział XIII Gospodarczy Krajowego Rejestru Sądowego pod nr KRS
                                    0000802199, o kapitale zakładowym w wysokości 5.000,00 złotych NIP:
                                    521-387-55-58, REGON 384286533. Dane osobowe Licencjobiorcy oraz osób
                                    przez niego wskazanych będą przetwarzane wyłącznie w celu realizacji
                                    umowy - na podstawie Art. 6 ust. 1 lit. b ogólnego rozporządzenia o
                                    ochronie
                                    danych osobowych z dnia 27 kwietnia 2016 r.;
                                </li>

                                <li>b) odbiorcami danych osobowych Licencjobiorcy oraz osób przez niego
                                    wskazanych będą wyłącznie podmioty uprawnione do uzyskania danych
                                    osobowych na podstawie przepisów prawa;
                                </li>

                                <li>c) dane osobowe Licencjobiorcy oraz osób przez niego wskazanych,
                                    przechowywane będą w oparciu o uzasadniony interes realizowany przez
                                    administratora,
                                </li>

                                <li>d) Licencjobiorca oraz osoby przez niego wskazane posiadają prawo do
                                    żądania
                                    od administratora dostępu do danych osobowych, ich sprostowania,
                                    usunięcia lub ograniczenia przetwarzania,
                                </li>
                                <li>e) Klient ma prawo wniesienia skargi do organu nadzorczego,</li>

                                <li>f) podanie danych osobowych Klienta jest dobrowolne, jednakże odmowa
                                    podania danych może skutkować odmową zawarcia umowy.
                                </li>

                                <li>5. Niniejszy Regulamin Ogólny wchodzi w życie z dniem 22.06.2020</li>
                            </ul>
                        </p>
                    </div>

                </div>
            </Layout>

        )
    }
}
